import { useEffect, useState } from 'react';
import check from '../../assets/img/icons/CheckMark.svg';
import deny from '../../assets/img/icons/extraBtnRedX.svg';
import PENDING from '../../assets/img/icons/Alert.svg';
import APPROVED from '../../assets/img/icons/Check Mark.svg';
import REJECTED from '../../assets/img/icons/extraBtnRedX.svg';
import PROCESSED from '../../assets/img/icons/grayCheckNew.svg';
import triggerSrc from '../../assets/img/icons/threeDots.svg';
import Pagination from 'react-js-pagination';
import Search from '../../assets/img/icons/lupa.svg';
import GenericTableNew from '../Layouts/GenericTableNew';
import { useSelector, useDispatch } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import Swal from 'sweetalert2';
import '../TableUsers/tableUserStyle.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import { customSelectNew } from '../../components/Layouts/react-select-custom';
import paginationStyles from '../Layouts/pagination.module.scss';

import { Col, Row } from 'react-bootstrap';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import CustomPopupExtend from '../Popup/customPopUpExtends';
import ModalNew from '../Layouts/ModalNew';
import {
  getDetail,
  getOverTimeList,
  updateOverTimeStatus,
} from '../../actions/approvedNewsActions';
import { costcenter_get } from '../../actions/costcenterActions';
import { formatToRcSelect, message } from '../../helpers/helpers';
import NumberFormat from 'react-number-format';

export const MyPayrollOverTimeList = () => {
  const store = useSelector(state => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'ovrMn',
  );
  const idAccount = store.loginReducer.user_data.id;
  const token = store.loginReducer.Authorization;
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const arrConCenter = store.costcenterReducer?.cc;
  const [trigger, setTrigger] = useState(0);
  const [elemDetail, setElemDetail] = useState({});
  const [arrDetail, setArrDetail] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    search: '',
    status: '',
    dateFrom: '',
    dateUntil: '',
    area: '',
    user: idAccount,
  });
  const formattedConCenter = formatToRcSelect(arrConCenter, 'id', 'description');
  const arrOverTime = store.approvedNewsReducer?.overTimeList;
  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/configuracion/miperfil');
    }
    dispatch(
      costcenter_get({
        eaccount: store.loginReducer.currentAccount.id,
        active: 1,
      }),
    );
    dispatch(getOverTimeList(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  const renderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className='col-md-2 text-start' style={{ paddingLeft: '0.5rem' }}>
          {elem.collaborator}
        </td>
        <td className='col-md-2 text-start'>&nbsp; {elem.job_title}</td>
        <td className='col-md-1 text-start'>{elem.area}</td>
        <td className='col-md-2 text-center'>{elem.hours}</td>
        <td className='col-md-2 text-center'>{elem.dateStart}</td>
        <td className='text-center'>{elem.entity_account_name}</td>
        <td
          className='col-md-2 text-center cursorPointer'
          title={
            elem.status === 'pending'
              ? 'Pendiente'
              : elem.status === 'approved'
                ? 'Aprobado'
                : elem.status === 'rejected'
                  ? 'Rechazado'
                  : elem.status === 'processed' && 'Procesado'
          }
        >
          <img
            alt=''
            src={
              elem.status === 'pending'
                ? PENDING
                : elem.status === 'approved'
                  ? APPROVED
                  : elem.status === 'rejected'
                    ? REJECTED
                    : elem.status === 'processed' && PROCESSED
            }
          ></img>
        </td>

        <td className='col-md-1 text-center cursorPointer'>
          <div>
            <CustomPopupExtend
              noHover
              triggerSrc={triggerSrc}
              showDetails={true}
              textDetails='Ver detalle'
              showDetailsEvent={() => handleClickDetail(elem)}
              showUndo={
                elem.status === 'pending'
                  ? false
                  : elem.status === 'approved'
                    ? true
                    : elem.status === 'rejected'
                      ? true
                      : elem.status === 'processed' && false
              }
              undoEvent={() => handleUndo(elem)}
            />
          </div>
        </td>
      </tr>
    );
  };

  const handleUndo = async elem => {
    try {
      Swal.fire({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `¿Desea deshacer esta hora extra?`,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Si, Continuar',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              const result = await updateOverTimeStatus(
                {
                  status_detail: 'pending',
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token,
              );

              if (result.success) {
                setTrigger(trigger + 1);
                return message('success', 'Éxito', result.message);
              } else {
                return message('error', 'Ha ocurrido un error', result.message);
              }
            } catch (error) {
              message('error', 'Error', error.message);
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return message('error', 'Error', error.message);
    }
  };

  const handleAuth = async elem => {
    try {
      Swal.fire({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `Se aprobarán las horas extra registradas por ${elem.collaborator}`,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Si, Continuar',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              const result = await updateOverTimeStatus(
                {
                  status_detail: 'approved',
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token,
              );

              if (result.success) {
                setShowModal(false);
                setTrigger(trigger + 1);
                return message('success', 'Éxito', result.message);
              } else {
                return message('error', 'Ha ocurrido un error', result.message);
              }
            } catch (error) {
              message('error', 'Error', error.message);
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return message('error', 'Error', error.message);
    }
  };
  const handleDeny = async elem => {
    try {
      Swal.fire({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `Se denegarán las horas extra registradas por ${elem.collaborator}`,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Si, Continuar',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              const result = await updateOverTimeStatus(
                {
                  status_detail: 'rejected',
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token,
              );

              if (result.success) {
                setShowModal(false);
                setTrigger(trigger + 1);
                return message('success', 'Éxito', result.message);
              } else {
                return message('error', 'Ha ocurrido un error', result.message);
              }
            } catch (error) {
              message('error', 'Error', error.message);
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return message('error', 'Error', error.message);
    }
  };

  const listElem = () => {
    let elemMap;
    if (arrOverTime !== undefined) {
      const elem2 = arrOverTime;
      if (elem2.length > 0) {
        elemMap = elem2.map(elem => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className='text-start'>
      &nbsp; Colaborador
    </th>,
    <th key={2} className='text-start'>
      &nbsp; Cargo
    </th>,
    <th key={3} className='text-start'>
      Área
    </th>,
    <th key={4} className='text-center'>
      Horas
    </th>,
    <th key={3} className='text-center'>
      Fecha
    </th>,
    <th key={2} className='text-center'>
      Cuenta
    </th>,
    <th key={6} className='text-center'>
      Estado
    </th>,
    <th key={7}>&nbsp;</th>,
  ];

  const handleClickDetail = async elem => {
    const result = await getDetail(
      {
        id: elem.id,
      },
      token,
    );
    if (result?.success) {
      setArrDetail(result?.results);
    }

    setElemDetail(elem);
    setShowModal(true);
  };

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  return (
    <>
      <ModalNew
        show={showModal}
        hideCancelButton={true}
        onHide={() => setShowModal(false)}
        title={'Horas extra registradas'}
        subtitle={
          elemDetail.status === 'pending'
            ? 'En espera'
            : elemDetail.status === 'approved'
              ? 'Aprobado'
              : elemDetail.status === 'rejected'
                ? 'Rechazado'
                : elemDetail.status === 'processed' && 'Procesado'
        }
        subtitleStyle={
          elemDetail.status === 'pending'
            ? 'text-warning'
            : elemDetail.status === 'approved'
              ? 'text-success'
              : elemDetail.status === 'rejected'
                ? 'text-danger'
                : elemDetail.status === 'processed' && 'text-secondary'
        }
        extraButtons={
          elemDetail.status === 'pending' && [
            {
              name: 'Denegar',
              icon: deny,
              event: () => {
                handleDeny(elemDetail);
              },
            },
            {
              name: 'Autorizar',
              icon: check,
              event: () => {
                handleAuth(elemDetail);
              },
            },
          ]
        }
        size={'750'}
      >
        <Row className={`d-flex ${tableStyles.bgColorClearBlue} pb-3 rounded`}>
          <h6 className={`${tableStyles.darkBlueText} pt-1`}>
            <b>Colaborador</b>
          </h6>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Nombre</p>
            <input
              placeholder='Escribe...'
              className='register-inputs text-secondary'
              value={elemDetail.area}
              disabled
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Cargo</p>
            <input
              placeholder='Escribe...'
              className='register-inputs text-secondary'
              value={elemDetail.job_title}
              disabled
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Fecha/Hora de entrada</p>
            <input
              placeholder='Escribe...'
              className='register-inputs text-secondary'
              value={elemDetail.dateStartTime}
              disabled
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Fecha/Hora de salida</p>
            <input
              placeholder='Escribe...'
              className='register-inputs text-secondary'
              value={elemDetail.dateEndTime}
              disabled
            />
          </Col>
        </Row>

        <Row className='d-flex mb-4 mt-2' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
          {arrDetail?.length > 0 &&
            arrDetail.map(e => {
              return (
                <>
                  <Col xs={6}>
                    <p className={`${tableStyles.crudModalLabel} `}>Tipo</p>
                    <input
                      placeholder='Escribe...'
                      className='register-inputs text-secondary'
                      value={e.description}
                      disabled
                    />
                  </Col>
                  <Col xs={3}>
                    <p className={`${tableStyles.crudModalLabel} `}>No. de horas</p>
                    <input
                      placeholder='Escribe...'
                      className='register-inputs text-secondary'
                      value={e.hours}
                      disabled
                    />
                  </Col>

                  <Col xs={3}>
                    <p className={`${tableStyles.crudModalLabel} `}>Valor</p>
                    <NumberFormat
                      placeholder='Escribe...'
                      className='register-inputs text-secondary'
                      disabled
                      value={e.hour_value}
                      prefix='$'
                      thousandSeparator
                    ></NumberFormat>
                  </Col>
                </>
              );
            })}
        </Row>
      </ModalNew>

      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Aprobar horas extras</h1>

          <Row>
            <Col className='d-block' xs={2}>
              <p className={`${tableStyles.crudModalLabel}`}>Estado</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                onChange={e => setFilters({ ...filters, status: e.value })}
                options={[
                  { value: '', label: '-Seleccione-' },
                  { value: 'pending', label: 'Pendiente' },
                  { value: 'approved', label: 'Aprobado' },
                  { value: 'rejected', label: 'Rechazado' },
                  { value: 'processed', label: 'Procesado' },
                ]}
                className='text-secondary'
                placeholder={'Seleccione'}
                styles={customSelectNew}
              ></Select>
            </Col>
            <Col className='d-block' xs={2}>
              <p className={`${tableStyles.crudModalLabel}`}>Área</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                onChange={e => setFilters({ ...filters, area: e.value })}
                options={formattedConCenter}
                className='text-secondary'
                placeholder={'Seleccione'}
                styles={customSelectNew}
              ></Select>
            </Col>
            <Col className='d-block' xs={2}>
              <p className={`${tableStyles.crudModalLabel}`}>Fecha desde</p>
              <input
                onChange={e => setFilters({ ...filters, dateFrom: e.target.value })}
                type='date'
                placeholder='Escribe...'
                className='register-inputs text-primary text-uppercase fw-bold'
                name='position'
              />
            </Col>
            <Col className='d-block' xs={2}>
              <p className={`${tableStyles.crudModalLabel}`}>Hasta</p>
              <input
                onChange={e => setFilters({ ...filters, dateUntil: e.target.value })}
                type='date'
                placeholder='Escribe...'
                className='register-inputs text-primary text-uppercase fw-bold'
                name='position'
              />
            </Col>
            <Col className='d-block align-self-end' xs={4}>
              <div className='pt-4'>
                <form onSubmit={e => handleSearch(e)} className='d-flex'>
                  <input
                    className={`${tableStyles.SearchNew} mr-2 text-secondary`}
                    onChange={e => setFilters({ ...filters, search: e.target.value })}
                    name='search'
                    type='text'
                    placeholder='Buscar...'
                  />
                  <img
                    src={Search}
                    className='cursorPointer'
                    width={'30px'}
                    height={'24px'}
                    // style={{ marginBottom: "2px" }}
                    alt='Search icon'
                    onClick={e => handleSearch(e)}
                  />
                </form>
              </div>
            </Col>
          </Row>

          <GenericTableNew headers={renderHeaders} dark={true}>
            {listElem()}
          </GenericTableNew>

          <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag. {store.approvedNewsReducer.overTimeList ? filters.page : ''}
              {' de '}
              {Math.ceil(store.approvedNewsReducer.rowTotal / filters.perpage)
                ? Math.ceil(store.approvedNewsReducer.rowTotal / filters.perpage)
                : '1'}{' '}
              ({store.approvedNewsReducer.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={store.approvedNewsReducer.rowTotal}
              pageRangeDisplayed={5}
              onChange={e => setFilters({ ...filters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};
