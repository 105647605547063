import { useEffect, useState } from 'react';
import PENDING from '../../assets/img/icons/Alert.svg';
import APPROVED from '../../assets/img/icons/Check Mark.svg';
import PREAPPROVED from '../../assets/img/icons/greenCircleCheck.svg';
import REJECTED from '../../assets/img/icons/extraBtnRedX.svg';
import PROCESSED from '../../assets/img/icons/grayCheckNew.svg';
import note from '../../assets/img/icons/ordSugess.svg';
import Pagination from 'react-js-pagination';
import Search from '../../assets/img/icons/lupa.svg';
import SEND from '../../assets/img/icons/searchFileGrey.svg';
import triggerSrc from '../../assets/img/icons/threeDots.svg';
import GenericTableNew from '../Layouts/GenericTableNew';
import { useSelector, useDispatch } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import Swal from 'sweetalert2';
import '../TableUsers/tableUserStyle.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import { customSelectNew } from '../../components/Layouts/react-select-custom';
import paginationStyles from '../Layouts/pagination.module.scss';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import CustomPopupExtend from '../Popup/customPopUpExtends';
import ModalNew from '../Layouts/ModalNew';
import { getPermissionsList, updatePermissionsStatus } from '../../actions/approvedNewsActions';
import { costcenter_get } from '../../actions/costcenterActions';
import { isEmptyOrUndefined, message } from '../../helpers/helpers';
import Loader from 'react-loader-spinner';
import { SupportVisualizer } from './SupportVisualizer';
import { customSwaltAlert } from '../../helpers';
import moment from 'moment';

export const AuthPermissions = () => {
  const store = useSelector(state => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'permMy',
  );

  const idAccount = store.loginReducer.user_data.id;
  const token = store.loginReducer.Authorization;
  const [isLoading, setIsLoading] = useState(false);
  const [showModalDescription, setShowModalDescription] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [elemDetail, setElemDetail] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    search: '',
    status: '',
    dateFrom: '',
    dateUntil: '',
    area: '',
    user: idAccount,
  });

  const arrPermisions = store.approvedNewsReducer?.permissionsList;
  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/configuracion/miperfil');
    }

    dispatch(
      costcenter_get({
        eaccount: store.loginReducer.currentAccount.id,
        active: 1,
      }),
    );
    dispatch(getPermissionsList(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  const [commentModal, setCommentModal] = useState({
    show: false,
    comment: '',
    isEnabled: false,
    elem: {},
  });
  const closeCommentModal = () => {
    setCommentModal({ ...commentModal, show: false, comment: '', isEnabled: false, elem: {} });
  };

  const showDescription = async elem => {
    if (isEmptyOrUndefined(elem.description)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'No se encontró descripción para esta solicitud',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    setElemDetail(elem);
    setShowModalDescription(true);
  };

  const getExtraButtons = elem => {
    const rejectAndDescription = [
      {
        text: 'Denegar',
        icon: REJECTED,
        event: myPermission.reject
          ? () =>
              setCommentModal({
                ...commentModal,
                show: true,
                comment: '',
                elem: elem,
                isEnabled: true,
              })
          : null,
      },
      {
        text: 'Descripción',
        icon: SEND,
        event: () => showDescription(elem),
      },
    ];

    const statusPending = () => {
      return [
        {
          text: 'Pre-aprobado por líder',
          icon: PREAPPROVED,
          event: () => handleAuth(elem),
        },
        ...rejectAndDescription,
      ];
    };

    const statusPreApproved = () => {
      return [
        {
          text: 'Denegar',
          icon: REJECTED,
          event: myPermission.reject
            ? () =>
                setCommentModal({
                  ...commentModal,
                  show: true,
                  comment: '',
                  elem: elem,
                  isEnabled: true,
                })
            : null,
        },
        {
          text: 'Descripción',
          icon: SEND,
          event: () => showDescription(elem),
        },
      ];
    };

    const defaulStatus = () => {
      return [
        {
          text: 'Descripción',
          icon: SEND,
          event: () => showDescription(elem),
        },
      ];
    };

    if (elem?.status === 'pending') return statusPending();

    if (elem?.status === 'pre-approved') return statusPreApproved();

    return defaulStatus();
  };

  const renderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className='col-md-2 text-start' style={{ paddingLeft: '0.5rem' }}>
          {elem.collaborator}
        </td>
        <td className='col-md-2 text-start'>&nbsp; {elem.job_title}</td>
        <td className='col-md-1 text-start'>
          {elem.type === 'paid' ? 'Remunerado' : 'No remunerado'}
        </td>
        <td className='col-md-2 text-center'>{elem.count}</td>
        <td className='col-md-2 text-center'>{elem.date}</td>
        <td className='col-md-2 text-center'>
          {
            !isEmptyOrUndefined(elem?.startTime)
              ? `${moment(elem?.startTime, 'H:mm:ss')?.format?.('H:mm:ss A')} - ${moment(elem?.endTime, 'H:mm:ss').format?.('H:mm:ss A')}`
              : '-'
          }
        </td>
        <td className='col-md-2 text-center'>{elem.entity_account_name}</td>
        <td
          className='col-md-2 text-center cursorPointer'
          title={
            elem.status === 'pending'
              ? 'Pendiente'
              : elem.status === 'pre-approved'
                ? 'Pre-aprobado por líder'
                : elem.status === 'rejected'
                  ? 'Rechazado'
                  : elem.status === 'processed' && 'Procesado'
          }
        >
          <img
            width={20}
            height={20}
            alt='icon'
            src={
              elem.status === 'pending'
                ? PENDING
                : elem.status === 'pre-approved'
                  ? PREAPPROVED
                  : elem.status === 'approved'
                    ? APPROVED
                    : elem.status === 'rejected'
                      ? REJECTED
                      : elem.status === 'processed' && PROCESSED
            }
          ></img>
        </td>
        <td className='col-md-1 text-center '>
          {elem.comment !== '' && elem.comment !== null && (
            <img
              alt='note'
              src={note}
              height='23px'
              className={`${tableStyles.svgFilterDarkBlue} cursorPointer`}
              onClick={() => {
                setCommentModal({ ...commentModal, show: true, comment: elem.comment });
              }}
            />
          )}
        </td>

        <td className='col-md-1 text-center cursorPointer'>
          <div>
            <CustomPopupExtend
              noHover
              triggerSrc={triggerSrc}
              showDetails={true}
              textDetails='Ver soporte'
              showDetailsEvent={() => handleClickDetail(elem)}
              showUndo={
                elem.status === 'pending'
                  ? false
                  : elem.status === 'approved'
                    ? true
                    : elem.status === 'pre-approved'
                      ? true
                      : elem.status === 'rejected'
                        ? true
                        : elem.status === 'processed' && false
              }
              undoEvent={() => handleUndo(elem)}
              extraButtons={getExtraButtons(elem)}
            />
          </div>
        </td>
      </tr>
    );
  };

  const handleUndo = async elem => {
    try {
      Swal.fire({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `¿Desea deshacer esta solicitud de permiso?`,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Si, Continuar',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              const result = await updatePermissionsStatus(
                {
                  status: 'pending',
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token,
              );

              if (result.success) {
                setTrigger(trigger + 1);
                return message('success', 'Éxito', result.message);
              } else {
                return message('error', 'Ha ocurrido un error', result.message);
              }
            } catch (error) {
              message('error', 'Error', error.message);
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return message('error', 'Error', error.message);
    }
  };

  const handleAuth = elem => {
    try {
      Swal.fire({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `Se aprobará el permiso solicitado por ${elem?.collaborator}`,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Si, Continuar',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          setIsLoading(true);
          const send = async () => {
            try {
              const result = await updatePermissionsStatus(
                {
                  status: 'pre-approved',
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token,
              );

              if (result.success) {
                setIsLoading(false);
                setShowModal(false);
                setTrigger(trigger + 1);
                return message('success', 'Éxito', result.message);
              } else {
                setIsLoading(false);
                return message('error', 'Ha ocurrido un error', result.message);
              }
            } catch (error) {
              setIsLoading(false);
              message('error', 'Error', error.message);
            }
          };
          send();
        }
      });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      return message('error', 'Error', error.message);
    }
  };
  const handleDeny = async elem => {
    try {
      customSwaltAlert({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `Se denegará el permiso al colaborador: ${elem.collaborator}`,
        confirmButtonText: 'Si, continuar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              const result = await updatePermissionsStatus(
                {
                  status: 'rejected',
                  reviewed_by: idAccount,
                  id: elem.id,
                  comment: commentModal.comment,
                },
                token,
              );

              if (result.success) {
                closeCommentModal();
                setShowModal(false);
                setTrigger(trigger + 1);
                return customSwaltAlert({
                  icon: 'success',
                  title: 'Actualizado exitosamente',
                  text: `Se ha denegado el permiso al colaborador: ${elem.collaborator}`,
                  confirmButtonText: 'Aceptar',
                  showCancelButton: false,
                });
              } else {
                return customSwaltAlert({
                  icon: 'warning',
                  title: 'Intenta de nuevo',
                  text: result.message,
                  confirmButtonText: 'Aceptar',
                  showCancelButton: false,
                });
              }
            } catch (error) {
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: result.message,
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: error.message,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  };

  const listElem = () => {
    let elemMap;
    if (arrPermisions !== undefined) {
      const elem2 = arrPermisions;
      if (elem2.length > 0) {
        elemMap = elem2.map(elem => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className='text-start'>
      &nbsp; Colaborador
    </th>,
    <th key={2} className='text-start'>
      &nbsp; Cargo
    </th>,
    <th key={3} className='text-start'>
      Tipo
    </th>,
    <th key={4} className='text-center'>
      Cantidad
    </th>,
    <th key={5} className='text-center'>
      Fecha
    </th>,
    <th key={6} className='text-center'>
      Horario
    </th>,
    <th key={7} className='text-center'>
      Cuenta
    </th>,
    <th key={8} className='text-center'>
      Estado
    </th>,
    <th key={9}>&nbsp;</th>,
    <th key={10}>&nbsp;</th>,
  ];

  const handleClickDetail = async elem => {
    if (isEmptyOrUndefined(elem.file)) {
      return message('info', 'Sin soporte', 'No se encontraron soportes para esta solicitud');
    }

    setElemDetail(elem);
    setShowModal(true);
  };

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  return (
    <>
      {isLoading ? (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      ) : (
        <></>
      )}

      {store?.approvedNewsReducer?.loadingPerm ? (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      ) : (
        <></>
      )}
      <ModalNew
        show={showModalDescription}
        hideCancelButton={true}
        onHide={() => setShowModalDescription(false)}
        title={''}
        subtitle={
          elemDetail.status === 'pending'
            ? 'En espera'
            : elemDetail.status === 'approved'
              ? 'Aprobado'
              : elemDetail.status === 'rejected'
                ? 'Rechazado'
                : elemDetail.status === 'processed' && 'Procesado'
        }
        subtitleStyle={
          elemDetail.status === 'pending'
            ? 'text-warning'
            : elemDetail.status === 'approved'
              ? 'text-success'
              : elemDetail.status === 'rejected'
                ? 'text-danger'
                : elemDetail.status === 'processed' && 'text-secondary'
        }
        size={'750'}
      >
        <p> {elemDetail?.description} </p>
        <SupportVisualizer file={elemDetail?.file} />
      </ModalNew>
      <ModalNew
        title='Comentarios'
        show={commentModal.show}
        // btnYesName={showModalRejected.justify !== "" && "Guardar"}
        btnNoName={'Cerrar'}
        size='400'
        btnYesEvent={commentModal.isEnabled ? () => handleDeny(commentModal.elem) : null}
        btnYesDisabled={commentModal.isEnabled ? false : true}
        onHide={() => closeCommentModal()}
        btnNoEvent={() => closeCommentModal()}
        btnNoDisabled={false}
      >
        <p className={tableStyles.crudModalLabel}>Comentarios</p>
        <textarea
          placeholder={'Escribir...'}
          id='txtArea'
          rows='10'
          cols='10'
          disabled={commentModal.isEnabled ? false : true}
          onChange={e => setCommentModal({ ...commentModal, comment: e.target.value })}
          defaultValue={commentModal.comment}
          style={{
            height: '4rem',
            borderRadius: '5px',
            border: '1.5px solid #7FADDE',
            padding: '0 0.05rem',
            width: '100%',
          }}
          className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
        ></textarea>
      </ModalNew>
      <ModalNew
        show={showModal}
        hideCancelButton={true}
        onHide={() => setShowModal(false)}
        title={'Soporte'}
        subtitle={
          elemDetail.status === 'pending'
            ? 'En espera'
            : elemDetail.status === 'approved'
              ? 'Aprobado'
              : elemDetail.status === 'rejected'
                ? 'Rechazado'
                : elemDetail.status === 'processed' && 'Procesado'
        }
        subtitleStyle={
          elemDetail.status === 'pending'
            ? 'text-warning'
            : elemDetail.status === 'approved'
              ? 'text-success'
              : elemDetail.status === 'rejected'
                ? 'text-danger'
                : elemDetail.status === 'processed' && 'text-secondary'
        }
        size={'750'}
      >
        <SupportVisualizer file={elemDetail?.file} />
      </ModalNew>

      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Autorizar permisos</h1>

          <Row>
            <Col className='d-block' xs={2}>
              <p className={`${tableStyles.crudModalLabel}`}>Estado</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                onChange={e => setFilters({ ...filters, status: e.value })}
                options={[
                  { value: '', label: '-Seleccione-' },
                  { value: 'pre-approved', label: 'Pre-aprobado por líder' },
                  { value: 'approved', label: 'Aprobado' },
                  { value: 'rejected', label: 'Rechazado' },
                  { value: 'processed', label: 'Procesado' },
                ]}
                className='text-secondary'
                placeholder={'Seleccione'}
                styles={customSelectNew}
              ></Select>
            </Col>
            <Col className='d-block' xs={2}>
              <p className={`${tableStyles.crudModalLabel}`}>Tipo</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                onChange={e => setFilters({ ...filters, type: e.value })}
                options={[
                  { value: '', label: '-Seleccione-' },
                  { value: 'paid', label: 'Remunerado' },
                  { value: 'unpaid', label: 'No remunerado' },
                ]}
                className='text-secondary'
                placeholder={'Seleccione'}
                styles={customSelectNew}
              ></Select>
            </Col>
            <Col xs={2}>
              <p className={`${tableStyles.crudModalLabel} `}>Fecha desde</p>
              <input
                placeholder='Escribe...'
                className='register-inputs text-primary  fw-bold'
                type='date'
                onChange={e => setFilters({ ...filters, dateFrom: e.target.value })}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSearch(e);
                  }
                }}
              />
            </Col>
            <Col xs={2}>
              <p className={`${tableStyles.crudModalLabel} `}>Hasta</p>
              <input
                placeholder='Escribe...'
                className='register-inputs text-primary  fw-bold'
                type='date'
                onChange={e => setFilters({ ...filters, dateUntil: e.target.value })}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSearch(e);
                  }
                }}
              />
                </Col>
            <Col className='d-block align-self-end' xs={4}>
              <div className='pt-4'>
                <form onSubmit={e => handleSearch(e)} className='d-flex'>
                  <input
                    className={`${tableStyles.SearchNew} mr-2 text-secondary`}
                    onChange={e => setFilters({ ...filters, search: e.target.value })}
                    name='search'
                    type='text'
                    placeholder='Buscar...'
                  />
                  <img
                    src={Search}
                    className='cursorPointer'
                    width={'30px'}
                    height={'24px'}
                    alt='Search icon'
                    onClick={e => handleSearch(e)}
                  />
                </form>
              </div>
            </Col>
          </Row>

          <GenericTableNew headers={renderHeaders} dark={true}>
            {listElem()}
          </GenericTableNew>

          <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag. {store.approvedNewsReducer.permissionsList ? filters.page : ''}
              {' de '}
              {Math.ceil(store.approvedNewsReducer.rowTotal / filters.perpage)
                ? Math.ceil(store.approvedNewsReducer.rowTotal / filters.perpage)
                : '1'}{' '}
              ({store.approvedNewsReducer.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={store.approvedNewsReducer.rowTotal}
              pageRangeDisplayed={5}
              onChange={e => setFilters({ ...filters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};
